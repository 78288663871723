import { MenuItem } from '../layout/shared/models/menu.model';

// menu items for vertcal and detached layout
const MENU_ITEMS: MenuItem[] = [
  {
    key: 'home',
    label: 'Home',
    isTitle: false,
    icon: 'mdi mdi-home-outline',
    url: '/home',
    role: 'ROLE_HOME_VISUALIZAR',
  },
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'mdi mdi-view-dashboard-outline',
    collapsed: true,
    children: [
      {
        key: 'administrador',
        label: 'Administrador',
        url: '/dashboard/administrador',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'dashboard',
        role: 'ROLE_DASHBOARD_ADMIN_VISUALIZAR',
      },
      {
        key: 'clube',
        label: 'Clube',
        url: '/dashboard/clube',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'dashboard',
        role: 'ROLE_DASHBOARD_CLUBE_VISUALIZAR',
      },
      {
        key: 'faturamento',
        label: 'Faturamento',
        url: '/dashboard/faturamento',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'dashboard',
        role: 'ROLE_DASHBOARD_FATURAMENTO_VISUALIZAR',
      },
    ],
  },
  {
    key: 'clubes',
    label: 'Clubes',
    isTitle: false,
    icon: 'mdi mdi-shield-half-full',
    // url: '/clubes',
    collapsed: true,
    children: [
      {
        key: 'clubes',
        label: 'Clube',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/clubes',
        role: 'ROLE_CLUBES_VISUALIZAR',
      },
      {
        key: 'cupons',
        label: 'Cupons',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/cupons',
        parentKey: 'clubes',
        role: 'ROLE_CUPONS_VISUALIZAR',
      },
      {
        key: 'parceiros',
        label: 'Parceiros',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/parceiros',
        parentKey: 'clubes',
        role: 'ROLE_PARCEIROS_VISUALIZAR',
      },
      {
        key: 'torcida-organizada',
        label: 'Torcida Organizada',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/torcida-organizada',
        parentKey: 'clubes',
        role: 'ROLE_TORCIDA_ORGANIZADA_VISUALIZAR',
      },
      {
        key: 'locais-venda',
        label: 'Locais de Venda',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/locais-venda',
        parentKey: 'clubes',
        role: 'ROLE_LOCAIS_DE_VENDA_VISUALIZAR',
      },
      {
        key: 'servicos-produtos',
        label: 'Serviços/Produtos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/servicos-produtos',
        parentKey: 'clubes',
        role: 'ROLE_SERVICOS_PRODUTOS_VISUALIZAR',
      },
      {
        key: 'modulos',
        label: 'Módulos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/modulos',
        parentKey: 'clubes',
        role: 'ROLE_MODULOS_VISUALIZAR',
      },
      {
        key: 'club-modulos',
        label: 'Módulos x Clubes',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/modulos-clubes',
        parentKey: 'clubes',
        role: 'ROLE_MODULOS_CLUBES_VISUALIZAR',
      },
    ],
  },
  {
    key: 'planos',
    label: 'Planos',
    isTitle: false,
    icon: 'mdi mdi-account-multiple',
    collapsed: true,
    children: [
      {
        key: 'planos',
        label: 'Planos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/planos',
        parentKey: 'planos',
        role: 'ROLE_PLANOS_VISUALIZAR',
      },
      {
        key: 'definicoes-beneficios',
        label: 'Benefícios',
        url: '/definicoes/beneficios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'planos',
        role: 'ROLE_BENEFICIOS_VISUALIZAR',
      },
    ],
  },

  {
    key: 'socios',
    label: 'Sócios',
    isTitle: false,
    icon: 'mdi mdi-handshake',
    collapsed: true,
    children: [
      {
        key: 'page-socios',
        label: 'Sócios',
        url: '/socios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'socios',
        role: 'ROLE_SOCIOS_VISUALIZAR',
      },
      {
        key: 'checkin',
        label: 'Checkin',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/checkin',
        parentKey: 'socios',
        role: 'ROLE_CHECKIN_VISUALIZAR',
      },
      {
        key: 'aprovacao-documentos',
        label: 'Aprovar Docs',
        url: '/aprovacao-de-documentos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'socios',
        role: 'ROLE_APROVACAO_DE_DOCUMENTOS_VISUALIZAR',
      },
      {
        key: 'consulta-externa',
        label: 'Consulta Externa',
        icon: 'mdi mdi-circle-medium',
        url: '/socios/consulta-externa',
        parentKey: 'socios',
        role: 'ROLE_CONSULTA_EXTERNA_VISUALIZAR',
      },
      {
        key: 'blacklist',
        label: 'Blacklist',
        url: '/socios/blacklist',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'socios',
        role: 'ROLE_BLACKLIST_VISUALIZAR',
      },
    ],
  },
  {
    key: 'relatorios',
    label: 'Relatórios',
    isTitle: false,
    icon: 'mdi mdi-file-chart-outline',
    collapsed: true,
    children: [
      {
        key: 'relatorio-de-pagamentos',
        label: 'Pagamentos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorio-de-pagamentos',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_PAGAMENTOS_VISUALIZAR',
      },
      {
        key: 'relatorio-de-recebimentos',
        label: 'Recebimentos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorio-de-recebimentos',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_RECEBIMENTOS_VISUALIZAR'
      },
      {
        key: 'relatorio-de-faturas',
        label: 'Faturas',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorio-de-faturas',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_FATURAS_VISUALIZAR',
      },
      {
        key: 'relatorio-dependentes',
        label: 'Dependentes',
        isTitle: false,
        url: '/relatorios/relatorio-dependentes',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'relatorios',
        role: 'ROLE_DEPENDENTES_VISUALIZAR',
      },
      {
        key: 'relatorio-de-ordens',
        label: 'Ordens',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-de-ordens',
        parentKey: 'relatorios',
        role: 'ROLE_ORDENS_VISUALIZAR', //ROLE_ORDERS_VISUALIZAR
      },
      {
        key: 'relatorio-inadimplentes',
        label: 'Inadimplentes',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-inadimplentes',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_INADIMPLENTES_VISUALIZAR',
      },
      {
        key: 'relatorio-recorrencia',
        label: 'Recorrência',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-recorrencias',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_INADIMPLENTES_VISUALIZAR',
      },
      {
        key: 'relatorio-mensal-socios',
        label: 'Sócios',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-mensal-socios',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_MENSAL_SOCIO_VISUALIZAR',
      },
      {
        key: 'relatorio-usuarios-socios',
        label: 'Usuários x Sócios',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-usuarios-socios',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_USUARIO_VISUALIZAR',
      },
      {
        key: 'relatorio-movimentacao-de-status',
        label: 'Movimentação de Status',
        isTitle: false,
        url: '/relatorio-movimentacao-de-status',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_STATUS_VISUALIZAR',
      },
      {
        key: 'relatorio-resumo-socios',
        label: 'Resumo de Sócios',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-resumo-socios',
        parentKey: 'relatorios',
        role: 'ROLE_RESUMO_DE_SOCIOS_VISUALIZAR',
      },
      {
        key: 'relatorio-quadro-resumo',
        label: 'Quadro Resumo',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-quadro-resumo',
        parentKey: 'relatorios',
        role: 'ROLE_QUADRO_RESUMO_VISUALIZAR',
      },
      {
        key: 'relatorio-renovacoes',
        label: 'Renovações',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-renovacoes',
        parentKey: 'relatorios',
        role: 'ROLE_RENOVACOES_VISUALIZAR',
      },
      {
        key: 'relatorio-upgrades',
        label: 'Upgrades',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-upgrades',
        parentKey: 'relatorios',
        role: 'ROLE_UPGRADES_VISUALIZAR',
      },
      {
        key: 'relatorio-estorno',
        label: 'Estornos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-estornos',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_ESTORNOS_VISUALIZAR',
      },
      {
        key: 'relatorio-cupons',
        label: 'Zé Delivery',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-cupons',
        parentKey: 'relatorios',
        role: 'ROLE_RELATORIO_DE_ESTORNOS_VISUALIZAR',
      },
    ],
  },
  {
    key: 'exports',
    label: 'Exportar',
    isTitle: false,
    icon: 'mdi mdi-export',
    collapsed: true,
    children: [
      {
        key: 'export-carteirinhas',
        label: 'Carteirinhas',
        url: '/exports/export-carteirinhas',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'exports',
        role: 'ROLE_CARTEIRINHAS_VISUALIZAR',
      },
    ],
  },
  {
    key: 'jogos',
    label: 'Jogos',
    isTitle: false,
    icon: 'mdi mdi-soccer',
    collapsed: true,
    children: [
      {
        key: 'page-jogos',
        label: 'Jogos',
        url: '/jogos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos',
        role: 'ROLE_JOGOS_VISUALIZAR',
      },
      {
        key: 'adversarios',
        label: 'Adversários',
        url: '/jogos/adversarios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos',
        role: 'ROLE_ADVERSARIOS_VISUALIZAR',
      },
      {
        key: 'campeonatos',
        label: 'Campeonatos',
        url: '/jogos/campeonatos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos',
        role: 'ROLE_CAMPEONATOS_VISUALIZAR',
      },
      {
        key: 'locais',
        label: 'Locais',
        url: '/jogos/locais',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos',
        role: 'ROLE_LOCAIS_VISUALIZAR',
      },
    ],
  },
  {
    key: 'ondas',
    label: 'Ondas',
    isTitle: false,
    icon: 'mdi mdi-waves',
    url: '/ondas',
    role: 'ROLE_ONDAS_VISUALIZAR',
  },
  {
    key: 'gamificacao',
    label: 'Gamificação',
    isTitle: false,
    icon: 'mdi mdi-gamepad',
    collapsed: true,
    children: [
      {
        key: 'promocoes',
        label: 'Experiências',
        url: '/gamificacao/experiencias',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'gamificacao',
        role: 'ROLE_EXPERIENCIAS_VISUALIZAR',
      },
      {
        key: 'acoes-pontuadoras',
        label: 'Ações Pontuadoras',
        url: '/gamificacao/acoes-pontuadoras',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'gamificacao',
        role: 'ROLE_ACOES_PONTUADORAS_VISUALIZAR',
      },
    ],
  },
  {
    key: 'mgm',
    label: 'MGM',
    isTitle: false,
    icon: 'mdi mdi-arrow-left-right',
    collapsed: true,
    children: [
      // {
      //   key: 'indicacoes',
      //   label: 'Relatório de Indicações',
      //   url: '/mgm/indicacoes',
      //   icon: 'mdi mdi-circle-medium',
      //   parentKey: 'mgm',
      //   role: 'ROLE_RELATORIO_DE_INDICACOES_VISUALIZAR'
      // },
      {
        key: 'socios-indicaram',
        label: 'Sócios que Indicaram',
        url: '/mgm/socios-indicaram',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'mgm',
        role: 'ROLE_SOCIOS_QUE_INDICARAM_VISUALIZAR',
      },
      {
        key: 'abatimento-pontos',
        label: 'Histórico de Pontos',
        url: '/mgm/abatimento-pontos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'mgm',
        role: 'ROLE_ABATIMENTO_DE_PONTOS_VISUALIZAR',
      },
    ],
  },
  {
    key: 'marketing',
    label: 'Marketing',
    isTitle: false,
    icon: 'mdi mdi-email-outline',
    collapsed: true,
    children: [
      {
        key: 'envios-email',
        label: 'E-mails em Massa',
        url: '/marketing/envios-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
        role: 'ROLE_E_MAILS_EM_MASSA_VISUALIZAR',
      },
      {
        key: 'notificacoes',
        label: 'Notificações',
        url: '/marketing/notificacoes',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
        role: 'ROLE_NOTIFICACOES_VISUALIZAR',
      },
      {
        key: 'modelosEmail',
        label: 'Modelos de E-mail',
        url: '/marketing/modelos-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
        role: 'ROLE_MODELOS_DE_E_MAIL_VISUALIZAR',
      },
      {
        key: 'tipos-email',
        label: 'Tipos de E-mail',
        url: '/marketing/tipos-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
        role: 'ROLE_TIPOS_DE_EMAIL_VISUALIZAR',
      },
      {
        key: 'variaveis-email',
        label: 'Variáveis de E-mail',
        url: '/marketing/variaveis-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
        role: 'ROLE_VARIAVEIS_DE_EMAIL_VISUALIZAR',
      },
      {
        key: 'email-imagens',
        label: 'Email Imagens',
        url: '/marketing/email-imagens',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
        role: 'ROLE_EMAIL_IMAGENS_VISUALIZAR',
      },
    ],
  },
  {
    key: 'parametros-consulta-externa',
    label: 'Integrações',
    isTitle: false,
    icon: 'mdi mdi-view-module',
    collapsed: true,
    children: [
      {
        key: 'parametros-consulta-externa',
        label: 'Parâmetros de Consulta Externa',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/parametros-consulta-externa',
        parentKey: 'parametros-consulta-externa',
        role: 'ROLE_PARAMETROS_DE_CONSULTA_EXTERNA_VISUALIZAR',
      },
      {
        key: 'integracoes',
        label: 'Integrações',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/integracoes',
        role: 'ROLE_INTEGRACOES_VISUALIZAR',
      },
      {
        key: 'rotinas-batch',
        label: 'Rotinas Batch',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/rotinas-batch',
        role: 'ROLE_ROTINAS_BATCH_VISUALIZAR',
      },
    ],
  },
  {
    key: 'account-launch',
    label: 'Administrativo',
    isTitle: false,
    icon: 'mdi mdi-cash-register',
    collapsed: true,
    children: [
      {
        key: 'account-launch',
        label: 'Lançamento de Contas',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/lancamento-de-contas',
        parentKey: 'account-launch',
        role: 'ROLE_LANCAMENTO_CONTA_VISUALIZAR',
      },
      {
        key: 'contratos',
        label: 'Contratos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/contratos',
        parentKey: 'account-launch',
        role: 'ROLE_CONTRATOS_VISUALIZAR',
      },
      {
        key: 'contas',
        label: 'Contas',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/contas',
        parentKey: 'account-launch',
        role: 'ROLE_CONTAS_VISUALIZAR',
      },
      {
        key: 'tipos-taxa-contrato',
        label: 'Tipos de Taxa Contrato',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-taxa-contrato',
        parentKey: 'account-launch',
        role: 'ROLE_TIPO_TAXA_CONTRATO_VISUALIZAR',
      },
      {
        key: 'fechamento-mensal',
        label: 'Fechamento Mensal',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/fechamento-mensal',
        parentKey: 'account-launch',
        role: 'ROLE_FECHAMENTO_MENSAL_VISUALIZAR',
      },

      {
        key: 'status-relatorio-fechamentos',
        label: 'Status Relatório Fechamento',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/status-relatorio-fechamento',
        parentKey: 'account-launch',
        role: 'ROLE_STATUS_RELATORIO_FECHAMENTO_VISUALIZAR',
      },
    ],
  },
  {
    label: 'Configurações',
    isTitle: true,
  },
  {
    key: 'configurar-backoffice',
    label: 'Configurar backoffice',
    isTitle: false,
    icon: 'mdi mdi-web',
    collapsed: true,
    children: [
      {
        key: 'login',
        label: 'Login',
        url: '/configurar-backoffice/login',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-backoffice',
        role: 'ROLE_LOGIN_VISUALIZAR',
      },
    ],
  },
  {
    key: 'configurar-portal',
    label: 'Configurar Portal',
    isTitle: false,
    icon: 'mdi mdi-web',
    collapsed: true,
    children: [
      {
        key: 'geral',
        label: 'Configuração Geral',
        url: '/configurar-portal/geral',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_CONFIGURACAO_GERAL_VISUALIZAR',
      },
      {
        key: 'cabecalho',
        label: 'Cabeçalho',
        url: '/configurar-portal/cabecalho',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_CABECALHO_VISUALIZAR',
      },
      {
        key: 'principal',
        label: 'Principal',
        url: '/configurar-portal/principal',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_PRINCIPAL_VISUALIZAR',
      },

      {
        key: 'config-planos',
        label: 'Planos',
        url: '/configurar-portal/planos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_PORTAL_PLANOS_VISUALIZAR',
      },
      {
        key: 'beneficios',
        label: 'Benefícios',
        url: '/configurar-portal/beneficios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_PORTAL_BENEFICIOS_VISUALIZAR',
      },
      {
        key: 'banner',
        label: 'Banner',
        url: '/configurar-portal/banner',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_BANNER_VISUALIZAR',
      },
      {
        key: 'conteudo-exclusivo-config',
        label: 'Conteúdo Exclusivo',
        url: '/configurar-portal/conteudo-exclusivo',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_CONTEUDO_EXCLUSIVO_VISUALIZAR',
      },
      {
        key: 'secao-video',
        label: 'Seção Vídeo',
        url: '/configurar-portal/secao-video',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_SECAO_VIDEO_VISUALIZAR',
      },
      {
        key: 'tabela-beneficios',
        label: 'Tabela de Benefícios',
        url: '/configurar-portal/tabela-beneficios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_TABELA_DE_BENEFICIOS_VISUALIZAR',
      },
      {
        key: 'proximos-confrontos',
        label: 'Próximos Confrontos',
        url: '/configurar-portal/proximos-confrontos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_PROXIMOS_CONFRONTOS_VISUALIZAR',
      },
      {
        key: 'tabela-ranking',
        label: 'Tabela de Ranking',
        url: '/configurar-portal/tabela-ranking',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_TABELA_DE_RANKING_VISUALIZAR',
      },
      {
        key: 'faq',
        label: 'FAQ',
        url: '/configurar-portal/faq',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_FAQ_VISUALIZAR',
      },
      {
        key: 'rodape',
        label: 'Rodapé',
        url: '/configurar-portal/rodape',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
        role: 'ROLE_RODAPE_VISUALIZAR',
      },
    ],
  },
  {
    key: 'definicoes',
    label: 'Definições',
    isTitle: false,
    icon: 'mdi mdi-tools',
    collapsed: true,
    children: [
      {
        key: 'balances',
        label: 'Balanço',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/balances',
        parentKey: 'definicoes',
        role: 'ROLE_BALANCO_VISUALIZAR',
      },
      {
        key: 'definicoes-termos',
        label: 'Termos de Uso',
        url: '/definicoes/termos-de-uso',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'definicoes',
        role: 'ROLE_TERMOS_DE_USO_VISUALIZAR',
      },
      {
        key: 'definicoes-politica-privacidade',
        label: 'Política de Privacidade',
        url: '/definicoes/politica-privacidade',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'definicoes',
        role: 'ROLE_POLITICA_DE_PRIVACIDADE_VISUALIZAR',
      },
      {
        key: 'conteudo-exclusivo',
        label: 'Conteúdo Exclusivo',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/conteudo-exclusivo',
        parentKey: 'definicoes',
        role: 'ROLE_PORTAL_CONTEUDO_EXCLUSIVO_VISUALIZAR',
      },
      {
        key: 'autor',
        label: 'Autor',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/autor',
        parentKey: 'definicoes',
        role: 'ROLE_AUTOR_VISUALIZAR',
      },
      {
        key: 'categorias',
        label: 'Categorias',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/categorias',
        parentKey: 'definicoes',
        role: 'ROLE_CATEGORIAS_VISUALIZAR',
      },
      {
        key: 'rede-social',
        label: 'Rede Social',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/rede-social',
        parentKey: 'definicoes',
        role: 'ROLE_REDE_SOCIAL_VISUALIZAR',
      },
      {
        key: 'cargos',
        label: 'Cargos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/cargos',
        parentKey: 'definicoes',
        role: 'ROLE_CARGOS_VISUALIZAR',
      },
      {
        key: 'departamentos',
        label: 'Departamentos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/departamentos',
        parentKey: 'definicoes',
        role: 'ROLE_DEPARTAMENTOS_VISUALIZAR',
      },
      {
        key: 'definicoes-perguntas-frequentes',
        label: 'Perguntas Frequentes',
        url: '/definicoes/faq',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'definicoes',
        role: 'ROLE_PERGUNTAS_FREQUENTES_VISUALIZAR',
      },
    ],
  },
  {
    key: 'usuariosHead',
    label: 'Segurança',
    isTitle: false,
    icon: 'mdi mdi-security',
    collapsed: true,
    children: [
      {
        key: 'usuarios',
        label: 'Usuários',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/usuarios',
        parentKey: 'usuarios',
        role: 'ROLE_USUARIOS_VISUALIZAR',
      },
      {
        key: 'perfis',
        label: 'Perfis',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/perfis',
        parentKey: 'usuarios',
        role: 'ROLE_PERFIS_VISUALIZAR',
      },
      {
        key: 'funcionalidades',
        label: 'Funcionalidades',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/funcionalidades',
        parentKey: 'usuarios',
        role: 'ROLE_FUNCIONALIDADES_VISUALIZAR',
      },
      {
        key: 'log-sistema',
        label: 'Log do Sistema',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/log-sistema',
        parentKey: 'usuarios',
        role: 'ROLE_LOG_DO_SISTEMA_VISUALIZAR',
      },
    ],
  },
  {
    key: 'tipos-acao',
    label: 'Geral',
    isTitle: false,
    icon: 'mdi mdi-cog',
    collapsed: true,
    children: [
      {
        key: 'tipos-acao-menu',
        label: 'Tipos de Ação Log',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-acao',
        parentKey: 'tipos-acao',
        role: 'ROLE_TIPOS_DE_ACAO_LOG_VISUALIZAR',
      },
      {
        key: 'tipos-plano',
        label: 'Tipos de Plano',
        url: '/tipos-plano',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
        role: 'ROLE_TIPOS_DE_PLANO_VISUALIZAR',
      },
      {
        key: 'tipo-status-pagamento',
        label: 'Tipo Status Pagamento',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipo-status-pagamento',
        parentKey: 'tipos-acao',
        role: 'ROLE_TIPO_STATUS_PAGAMENTO_VISUALIZAR',
      },
      {
        key: 'tipo-de-pagamento',
        label: 'Tipo de Pagamento',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipo-de-pagamento',
        parentKey: 'tipos-acao',
        role: 'ROLE_TIPO_DE_PAGAMENTO_VISUALIZAR',
      },
      {
        key: 'tipos-taxa',
        label: 'Tipos de Taxa',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-taxa',
        parentKey: 'tipos-acao',
        role: 'ROLE_TIPOS_DE_TAXA_VISUALIZAR',
      },
      {
        key: 'valores',
        label: 'Valores Taxa',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/valores-taxa',
        parentKey: 'tipos-acao',
        role: 'ROLE_VALORES_TAXA_VISUALIZAR',
      },
      {
        key: 'tipos-fatura',
        label: 'Tipos de Fatura',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-fatura',
        parentKey: 'tipos-acao',
        role: 'ROLE_TIPOS_DE_FATURA_VISUALIZAR',
      },
      {
        key: 'motivos-cancelamento-fatura',
        label: 'Motivos Canc. Fatura',
        url: '/socios/motivos-cancelamento-fatura',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
        role: 'ROLE_MOTIVOS_DE_CANCELAMENTO_DE_FATURA_VISUALIZAR',
      },
      {
        key: 'motivos-estorno-fatura',
        label: 'Motivos Estorno Fatura',
        url: '/socios/motivos-estorno-fatura',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
        role: 'ROLE_MOTIVOS_DE_ESTORNO_DE_FATURA_VISUALIZAR',
      },
      {
        key: 'origem-socio',
        label: 'Origem Sócio Torcedor',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/origem-socio',
        parentKey: 'tipos-acao',
        role: 'ROLE_ORIGEM_SOCIO_TORCEDOR_VISUALIZAR',
      },
      {
        key: 'motivos-cadastro',
        label: 'Motivos de Cadastro',
        url: '/socios/motivos-cadastro',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
        role: 'ROLE_MOTIVOS_DE_CADASTRO_VISUALIZAR',
      },
      {
        key: 'situacoes',
        label: 'Situações',
        url: '/socios/situacoes',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
        role: 'ROLE_SITUACOES_VISUALIZAR',
      },
      {
        key: 'tags',
        label: 'Tags',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tags',
        parentKey: 'tipos-acao',
        role: 'ROLE_TAGS_VISUALIZAR',
      },
      {
        key: 'assuntos-crm',
        label: 'Assuntos CRM',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/assuntos-crm',
        parentKey: 'tipos-acao',
        role: 'ROLE_ASSUNTOS_CRM_VISUALIZAR',
      },
      {
        key: 'classificacao-socio',
        label: 'Classificação ST',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/classificacao-socio',
        parentKey: 'tipos-acao',
        role: 'ROLE_CLASSIFICACAO_SOCIO_TORCEDOR_VISUALIZAR',
      },

      {
        key: 'status-plano',
        label: 'Status do Plano',
        icon: 'mdi mdi-circle-medium',
        url: '/status-plano',
        parentKey: 'tipos-acao',
        role: 'ROLE_STATUS_DO_PLANO_VISUALIZAR',
      },
      {
        key: 'motivos-cancelamento',
        label: 'Motivos Cancelamento',
        url: '/motivos-cancelamento',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
        role: 'ROLE_MOTIVOS_DE_CANCELAMENTO_VISUALIZAR',
      },
    ],
  },

  // {
  //     key: 'forms',
  //     label: 'forms',
  //     isTitle: false,
  //     icon: 'mdi mdi-handshake',
  //     url: '/forms',
  // }
];

const HORIZONTAL_MENU_ITEMS: MenuItem[] = [];

export { MENU_ITEMS, HORIZONTAL_MENU_ITEMS };
